import axios from 'axios'

export default {
    items(locationId) {
        return axios.get(`${process.env.VUE_APP_API_URL}/locations/${locationId}/catalog-categories`);
    },
    updateItem(data) {
        // eslint-disable-next-line
        return axios.put(`${process.env.VUE_APP_API_URL}/catalog-items/${data.id}`, {'active': data.state});
    },
    updateCategoryItems(locationId, data) {
        // eslint-disable-next-line
        return axios.put(`${process.env.VUE_APP_API_URL}/locations/${locationId}/catalog-categories/${data.id}`, {'active': data.state});
    },
    createOrder(data) {
        return axios.post(`${process.env.VUE_APP_API_URL}/orders`, data);
    }
}