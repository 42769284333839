import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyALu62it3m2llFfJFdcPIikeJ-JAeacJCg",
    authDomain: "onme-c1a37.firebaseapp.com",
    databaseURL: "https://onme-c1a37.firebaseio.com",
    projectId: "onme-c1a37",
    storageBucket: "onme-c1a37.appspot.com",
    messagingSenderId: "13720415871",
    appId: "1:13720415871:web:6abad0121b253026"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {
    db
}