import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import catalog from './modules/catalog'
import dashboard from './modules/dashboard'
import notification from './modules/notification'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    catalog,
    dashboard,
    notification
  },
  strict: debug
})
