<template>
  <div class="dashboard">
    <div class="dashboard__alert" v-if="alert" @click="alert = false"></div>
    <layout>
      <md-app-drawer :md-active.sync="showSummary" md-persistent="full" class="dashboard__summary-container">
        <orders-summary></orders-summary>
      </md-app-drawer>
      <md-app-content>
        <div v-if="location.workload_status" class="dashboard__workload-info" >
          <div class="dashboard__workload-info-label">Workload-Info:</div>
          <div class="dashboard__workload-info-text" v-html="location.workload_status_text" :style="{'background': 'rgb('+location.workload_status_color+')'}"></div>
        </div>
        <md-content>
          <md-progress-spinner v-if="!loaded" :md-stroke="4" md-mode="indeterminate"
                               class="dashboard__spinner"></md-progress-spinner>
          <orders v-if="loaded"></orders>
          <md-button v-if="loaded" class="md-fab md-mini md-primary" @click="showSummary = !showSummary">
            <md-icon>vertical_split</md-icon>
          </md-button>
        </md-content>
      </md-app-content>
    </layout>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapGetters, mapActions} from 'vuex'
import Orders from '../components/Orders'
import Layout from '../components/Layout'
import OrdersSummary from "@/components/OrdersSummary.vue";

export default {
  name: 'home',
  components: {
    OrdersSummary,
    Orders,
    Layout
  },
  data() {
    return {
      alert: false,
      showSummary: false
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('dashboard', [
      'loaded',
      'location',
      'outlets'
    ]),
    openOrders: function () {
      return this.location.orders.filter((order) => {
        return order.items.some((item) => {
          return this.outlets.indexOf(item.outlet_id) > -1 && !item.picked_up_at && !item.cancelled_at
        })
      })
    }
  },
  methods: {
    ...mapActions('dashboard', [
      'loadLocation',
      'togglePaused'
    ]),
    checkAlert() {
      this.alert = false
      if (this.isAppIdle) {
        if (this.openOrders.length > 0) {
          this.alert = true
        }
      }
    }
  },
  mounted() {
    setInterval(function () {
      this.checkAlert()
    }.bind(this), 3000)

    this.loadLocation(this.user.location_id)
  }
}
</script>
