import Vue from 'vue'
import * as types from '../mutation-types'
import catalog from '../../api/catalog';

// initial state
const state = {
    loaded: false,
    locationId: null,
    categories: null,
    error: null,
    cart: {},
}

// getters
const getters = {
    loaded: state => state.loaded,
    cart: state => state.cart,
    cartTotal: state => {
        let total = 0;
        Object.keys(state.cart).forEach(key => {
            total += state.cart[key].price * state.cart[key].quantity
        });
        return total
    },
    cartQuantity: state => {
        let quantity = 0;
        Object.keys(state.cart).forEach(key => {
            quantity += state.cart[key].quantity
        });
        return quantity
    },
    categories: state => state.categories,
    error: state => state.error,
}

// actions
const actions = {
    async updateItem({dispatch}, data) {
        try {
            await catalog.updateItem(data)
            dispatch('notification/updateError', null, {root: true})
        } catch (error) {
            dispatch('notification/updateError', error, {root: true})
        }
    },
    async updateCategoryItems({commit, dispatch, state}, data) {
        try {
            let resp = await catalog.updateCategoryItems(state.locationId, data)
            commit(types.CATALOG_GET_ITEMS, resp.data)
            dispatch('notification/updateError', null, {root: true})
        } catch (error) {
            dispatch('notification/updateError', error, {root: true})
        }
    },
    async loadItems({commit, dispatch}, locationId) {
        try {
            let resp = await catalog.items(locationId)
            commit(types.CATALOG_SET_LOCATION, locationId)
            commit(types.CATALOG_GET_ITEMS, resp.data)
            dispatch('notification/updateError', null, {root: true})
        } catch (error) {
            dispatch('notification/updateError', error, {root: true})
        }
    },
    addToCart({commit}, item) {
        commit(types.CATALOG_ADD_TO_CART, item)
    },
    removeFromCart({commit}, item) {
        commit(types.CATALOG_REMOVE_FROM_CART, item)
    },
    resetCart({commit}) {
        commit(types.CATALOG_RESET_CART)
    }
}

// mutations
const mutations = {
    [types.CATALOG_SET_LOCATION](state, locationId) {
        state.locationId = locationId
    },
    [types.CATALOG_UPDATE_ITEM](state, data) {
        if (data) {
            state.editItem = data
        } else {
            state.editItem = null
        }
    },
    [types.CATALOG_GET_ITEMS](state, data) {
        Vue.set(state, 'categories', data);
    },
    [types.CATALOG_ERROR](state, error) {
        state.error = error
    },
    [types.CATALOG_ADD_TO_CART](state, item) {
        if (!state.cart[item.id]) {
            Vue.set(state.cart, item.id, item)
            Vue.set(state.cart[item.id], 'quantity', 1)
        } else {
            state.cart[item.id].quantity++
        }
    },
    [types.CATALOG_REMOVE_FROM_CART](state, item) {
        if (state.cart[item.id]) {
            if (state.cart[item.id].quantity > 1) {
                state.cart[item.id].quantity--
            } else {
                Vue.delete(state.cart, item.id)
            }
        }
    },
    [types.CATALOG_RESET_CART](state) {
        state.cart = {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
