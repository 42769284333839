<template>
    <div class="order" :class="[{'order--ready': isReady}, {'order--disabled': history}]">
        <md-dialog-confirm
                :md-active.sync="cancelDialog"
                md-title="Stornieren"
                :md-content="'Möchtest du diese Bestellung mit der Nummer '+order.pick_up_number+' wirklich stornieren?'"
                md-confirm-text="Bestätigen"
                md-cancel-text="Abrechen"
                @md-cancel="cancelDialog = false"
                @md-confirm="onConfirmCancel"/>

        <template v-if="!isCancelled">
            <div class="order__service-hint" :class="{'order__service-hint--blue': isTableService}">
                <template v-if="!isTableService">
                    <span>Abholen</span>
                    <span class="order__table-hint">
                      <template v-if="order.table_number">Position {{order.table_number}} | </template>
                      <template v-if="order.phone_number">{{order.phone_number}}</template>
                    </span>
                </template>
                <span v-if="isTableService">Bringen!</span>
            </div>
        </template>

        <template v-if="isCancelled">
            <div class="order__service-hint">
                <span>Storniert</span>
            </div>
        </template>

        <div class="order__header">
            <div class="md-layout">
                <div class="md-layout-item md-size-30">
                    <div class="order__number" v-if="!isTableService">
                        {{order.pick_up_number}}
                    </div>
                    <div class="order__number order__number--blue" v-if="isTableService">
                        <span class="order__number-label">Tisch Nr.</span>
                        <span>{{order.table_number}}</span>
                    </div>
                </div>
                <div class="md-layout-item">
                    <div class="order__time">
                        {{orderTime}}
                    </div>
                    <div class="order__meta">
                        <div>Tips CHF {{(order.tip / 100).toFixed(2)}}</div>
                        <div v-if="order.reminded && !history" class="order__reminder-text">{{reminderText}}</div>
                    </div>
                </div>
                <div class="md-layout-item md-size-15" v-if="!isCancelled">
                    <md-menu md-size="small" class="order__more-menu">
                        <md-button md-menu-trigger class="order__more-button">
                            <md-icon>more_vert</md-icon>
                        </md-button>

                        <md-menu-content>
                            <md-menu-item @click="cancelDialog = true">Stornieren</md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </div>
            </div>
        </div>

        <md-divider class="order__devider"></md-divider>

        <div class="order__items" :ref="'order-items_' + order.id">
            <div class="order__item" :key="item.id" v-for="item in items"
                 :class="{'order__item--done': isDone(item.id), 'order__item--ready': item.ready_at, 'order__item--cancelled': item.cancelled_at}" @click="toggleDone(item.id)"
                :style="getItemStyles(item)">
                <div class="md-layout">
                    <div class="md-layout-item order__item-amount md-size-15">
                      <div>{{item.quantity}}</div>
                    </div>
                    <div class="md-layout-item">
                        <div class="order__item-name">{{item.pos_text ?? item.name}}</div>
                        <div class="order__item-quantity" v-if="!item.pos_text">{{item.subtitle}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="order__more-indicator" v-if="order && isOverflowing && !scrollEnd"></div>

        <div class="order__controls" v-if="!history">
            <div>
                <md-button class="order__button order__button--pickup" v-if="isReady" @click="setPickedUp">
                    <span v-if="isTableService">Serviert</span>
                    <span v-if="!isTableService">Abgeholt</span>
                </md-button>
            </div>
            <div>
                <md-button class="order__button md-primary" @click="setReady" v-if="!isReady">
                    Ready
                </md-button>
            </div>
        </div>
        <md-progress-bar class="order__progress-bar" v-if="loading" md-mode="query"></md-progress-bar>
    </div>
</template>

<script>
    // @ is an alias to /src
    import moment from 'moment'
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: 'Order',
        props: [
            'order',
            'history'
        ],
        data() {
            return {
                loading: false,
                reminderText: "",
                orderTime: "",
                cancelDialog: null,
                done: [],
                reminderDisabled: false,
                reminderInterval: null,
                scrollEnd: false
            }
        },
        computed: {
            ...mapGetters('dashboard', [
                'outlets'
            ]),
            isReady() {
                return this.items.every(item => item.ready_at)
            },
            isCancelled() {
              return this.items.every(item => item.cancelled_at)
            },
            isTableService() {
                return this.order.service_type === 'table';
            },
            isOverflowing() {

                if(this.$refs['order-items_' + this.order.id]) {
                    let el = this.$refs['order-items_' + this.order.id]
                    return this.checkOverflow(el)
                } else {
                    return false
                }
            },
            items() {
                return this.order.items.filter(item => this.outlets.indexOf(item.outlet_id) !== -1);
            },
            itemIds() {
                return this.items.map(item => item.id)
            }
        },
        methods: {
            ...mapActions('dashboard', [
                'ready',
                'pickUp',
                'remind',
                'cancel'
            ]),
            updateOrderTime: function () {
                moment.locale('de');
                this.orderTime = moment.utc(this.order.created_at).local().fromNow()
            },
            updateReminderText() {
                if (this.order.reminded) {
                    this.reminderText = this.order.reminder_count + '. Erinnerung ' + moment.utc(this.order.reminded).local().fromNow()
                }
            },
            updateReminderButton() {

                var waiting = 30

                var reminded = moment.utc(this.order.reminded).local().seconds();
                var now = moment().local().seconds();
                var diff = waiting - (now - reminded);

                if (diff <= waiting && diff >= 0) {
                    this.reminderDisabled = diff
                } else {
                    this.reminderDisabled = false
                }
            },
            async setReady() {
                if (!this.isReady && !this.loading) {
                    this.loading = true;
                    await this.ready({order: this.order.id, items: this.itemIds});
                    this.loading = false;
                }
            },
            async setPickedUp() {
                if (!this.loading) {
                  this.loading = true;
                  await this.pickUp({order: this.order.id, items: this.itemIds});
                  this.loading = false;
                }
            },
            //@todo: implement reminder
            async sendReminder() {
                if (!this.reminderDisabled) {
                    //this.order.reminded = moment();
                    this.loading = true;
                    await this.remind(this.order.id);
                    this.loading = false;
                }
            },
            async onConfirmCancel() {
                this.loading = true;
                await this.cancel({order: this.order.id, items: this.itemIds});
                this.loading = false;
            },
            update() {
                this.updateOrderTime();
                this.updateReminderText();
            },
            toggleDone(id) {

                let index = this.done.indexOf(id)

                if (index > -1) {
                    this.done.splice(index, 1);
                } else {
                    this.done.push(id);
                }
            },
            isDone(id) {
                return (this.done.indexOf(id) > -1);
            },
            getItemStyles(item) {
              let styles = {}
              if (!this.isDone(item.id) && item.pos_color) {
                styles.background = 'rgb(' + item.pos_color + ')'
              }
              return styles
            },
            checkOverflow(el) {
                let curOverflow = el.style.overflow;

                let isOverflowing = el.clientWidth < el.scrollWidth
                    || el.clientHeight < el.scrollHeight;

                el.style.overflow = curOverflow;

                return isOverflowing;
            }
        },
        created() {
            this.update()
            setInterval(function () {
                this.update()
            }.bind(this), 5000)
            this.updateReminderButton()
            setInterval(function () {
                this.updateReminderButton()
            }.bind(this), 1000)
        },
        mounted() {
            this.$refs['order-items_' + this.order.id].onscroll = () => {
                let el =  this.$refs['order-items_' + this.order.id];
                if(el.scrollTop === (el.scrollHeight - el.offsetHeight)) {
                    this.scrollEnd = true
                } else {
                    this.scrollEnd = false
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .order {
        position: relative;
        height: 100%;
        width: 190px;
        min-width: 190px;
        border-radius: 8px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07), 0 0 4px 0 rgba(0, 0, 0, 0.09);
        background-color: #ffffff;
        border: solid 1px #dde8ef;
        margin-right: 10px;
    }

    .order--ready {
        border-color: #00b300;

        .order__pick-up-number,
        .order__number{
            background-color: #00b300;
        }
    }

    .order--disabled {
        border: none;

        .order__service-hint {
            background-color: #999;
        }

        .order__number {
            background-color: #999;
        }

        .order__item--ready {
          color: var(--md-theme-default-text-primary-on-background, rgba(0, 0, 0, 0.87));
        }
    }

    .order__remind-button {
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translateY(-50%);

        &--disabled {
            background: #AAA !important;
        }
    }

    .order__number {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 45px;
        height: 45px;
        border-radius: 4px;
        background-color: #444;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        line-height: 50px;
        color: #FFF;

        &--blue {
            background-color: #428dfc;
            line-height: 1;
        }
    }

    .order__number-label {
        display:block;
        font-size: 8px;
        line-height: 1;
        padding: 8px 0 2px;
    }

    .order__header {
        padding: 12px 12px 0 12px;
    }

    .order__service-hint {
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.1;
        background: #444;
        color: #FFF;
        padding: 9px 0 11px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 44px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--blue {
            background: #428dfc;
        }
    }

    .order__table-hint {
      display: block;
      font-size: 10px;
      margin-top: 2px;
    }

    .order__phone-hint {
      display: block;
      font-size: 10px;
    }

    .order__time {
        font-size: 12px;
    }

    .order__reminder-text {
        color: #ff5252;
    }

    .order__more-menu {
        width: 20px;
    }

    .order__more-button {
        min-width: 24px;
    }

    .order__devider {
        margin: 12px 0 4px;
    }

    .order__button {
        width: 100%;
        height: 54px;
        display: inline;
        margin: 0 0 15px;
        border-radius: 4px;
        border: solid 1px #276ef1;
        background: #276ef1;
        color: white !important;
        margin: 0;

        &--cancel {
            color: rgba(0, 0, 0, 0.38) !important;
            border: solid 1px rgba(0, 0, 0, 0.38);
        }

        &--pickup {
            background: #00b300;
            border: solid 1px #00b300;
        }
    }

    .order__progress-bar {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .order__meta {
        color: rgba(0, 0, 0, 0.38);
        font-size: 12px;
        line-height: 14px;
        overflow: auto;
    }

    .order__item {
        padding: 10px 12px;
        border-bottom: 1px solid #EEE;
    }

    .order__item--done {
        color: #FFFFFF;
        background: #00b300;

        &.order__item--ready {
            color: #00b300;
            background: transparent;
        }
    }

    .order__item--ready {
      color: #00b300;
    }

    .order__item--cancelled {
      color: #ff5252;
    }

    .order__items {
        position: relative;
        height: calc(100% - 220px);
        overflow-x: hidden;
        overflow-y: auto;
    }

    .order__item-amount {
        font-size: 12px;
        font-weight: bold;

      div::after {
        font-size: 10px;
        content: 'x';
      }
    }

    .order__item-name,
    .order__item-price {
        font-size: 14px;
        font-weight: bold;
    }

    .order__item-quantity {
        font-size: 12px;
    }

    .order__controls {
        padding: 12px;
        position: absolute;
        width: 100%;
        bottom: 0;
        box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.09);
    }

    @keyframes bounceMoreIndicator {
        0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(8px);
        }
        60% {
            transform: translateY(6px);
        }
    }

    .order__more-indicator {
        position: absolute;
        bottom: 94px;
        left: 50%;
        z-index: 11;

        width: 14px;
        height: 16px;

        transform: translateX(-50%);

        &::before {
            display: block;
            width: 14px;
            height: 16px;
            background: url('../assets/angle-down-solid.svg');
            background-size: cover;
            content: '';

            animation: bounceMoreIndicator 2s infinite;
        }
    }
</style>
