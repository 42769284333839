import * as types from '../mutation-types'
import auth from '../../api/auth';
import dashboard from '../../api/dashboard';


// initial state
const state = {
    user: null
}

// getters
const getters = {
    user: state => state.user
}


// actions
const actions = {
    async authenticate({commit}, data) {
        let resp = await auth.login(data.username, data.password)
        commit(types.AUTH_LOGIN, resp.data)
    },
    async logout({commit}, locationId) {
        await dashboard.logout(locationId);
        commit(types.AUTH_LOGOUT)
    },
    async setUser({commit}, data) {
        commit(types.AUTH_LOGIN, data)
    },
}

// mutations
const mutations = {
    [types.AUTH_LOGIN](state, data) {
        state.user = data
    },
    [types.AUTH_LOGOUT](state) {
        state.user = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
