<template>
  <div class="orders-summary">
    <div class="orders-summary__item" v-for="item in items" :key="item.data.catalog_item_id" :style="{'background': 'rgb('+item.data.pos_color+')'}">
      <div class="md-layout">
        <div class="md-layout-item orders-summary__item-amount md-size-15">
          <div>{{item.quantity}}</div>
        </div>
        <div class="md-layout-item">
          <div class="orders-summary__item-name">{{item.data.pos_text ?? item.data.name}}</div>
          <div class="orders-summary__item-quantity" v-if="!item.data.pos_text">{{item.data.subtitle}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapGetters} from 'vuex'

export default {
  name: 'OrdersSummary',
  data() {
    return {
      menuVisible: false,
      searchTerm: ''
    }
  },
  computed: {
    ...mapGetters('dashboard', [
      'location',
      'filterNumber',
      'outlets'
    ]),
    items: function () {
      let items = {};
      if (this.location.orders) {
        this.location.orders.forEach((order) => {
          order.items.forEach((item) => {
            if (this.outlets.indexOf(item.outlet_id) > -1 && !item.picked_up_at && !item.cancelled_at && !item.ready_at) {
              if (!items[item.catalog_item_id]) {
                items[item.catalog_item_id] = {
                  data: item,
                  quantity: 0
                }
              }
              items[item.catalog_item_id].quantity += item.quantity;
            }
          });
        });
      }
      return items;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.orders-summary {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  overflow: auto;

  &__item {
    width: 100%;
    padding: 12px 12px;
    border-bottom: 1px solid #EEE;
  }

  &__item-name {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
  }

  &__item-amount {
    font-size: 12px;
    font-weight: bold;

    div::after {
      font-size: 10px;
      content: 'x';
    }
  }
}
</style>
