import * as types from '../mutation-types'
import dashboard from '../../api/dashboard';


// initial state
const state = {
    loaded: false,
    filterNumber: null,
    location: {
        name: ''
    },
    outlets: [],
}

// getters
const getters = {
    loaded: state => state.loaded,
    location: state => state.location,
    filterNumber: state => state.filterNumber,
    outlets: state => state.outlets,
}

// actions
const actions = {
    async loadLocation({commit}, locationId) {
        let resp = await dashboard.loadLocation(locationId)
        commit(types.DASHBOARD_LOAD_LOCATION, resp.data)
    },
    async refreshLocation({commit}, locationId) {
        dashboard.refreshLocation(locationId).then((resp) => {
            commit(types.DASHBOARD_LOAD_LOCATION, resp.data)
        }).catch(() => {})
    },
    async ready({commit}, data) {
        await dashboard.ready(data.order, data.items);
        let resp = await dashboard.loadLocation(state.location.id)
        commit(types.DASHBOARD_LOAD_LOCATION, resp.data)
    },
    async pickUp({commit}, data) {
        await dashboard.pickUp(data.order, data.items);
        let resp = await dashboard.loadLocation(state.location.id)
        commit(types.DASHBOARD_LOAD_LOCATION, resp.data)
    },
    async remind({commit}, orderId) {
        await dashboard.remind(orderId);
        let resp = await dashboard.loadLocation(state.location.id)
        commit(types.DASHBOARD_LOAD_LOCATION, resp.data)
    },
    async cancel({commit}, data) {
        await dashboard.cancel(data.order, data.items);
        let resp = await dashboard.loadLocation(state.location.id)
        commit(types.DASHBOARD_LOAD_LOCATION, resp.data)
    },
    updateFilterNumber({commit}, number) {
        commit(types.DASHBOARD_FILTER_ORDERS, number)
    },
    updateOutlets({commit}, outlets) {
        commit(types.DASHBOARD_UPDATE_OUTLETS, outlets)
    }
}

// mutations
const mutations = {
    [types.DASHBOARD_FILTER_ORDERS](state, number) {
        state.filterNumber = number
    },
    [types.DASHBOARD_LOAD_LOCATION](state, data) {
        state.loaded = true
        state.location = data
    },
    [types.DASHBOARD_UPDATE_ORDER](state, data) {
        state.loaded = true
        state.location = data
    },
    [types.DASHBOARD_TOGGLE_PAUSED](state) {
        state.paused = !state.paused
    },
    [types.DASHBOARD_UPDATE_OUTLETS](state, data) {
        state.outlets = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
