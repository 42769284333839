<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import {mapGetters, mapActions} from 'vuex'
    import store from 'store'

    export default {
        name: 'app',
        computed: {
            ...mapGetters('auth', [
                'user',
                'location'
            ])
        },
        methods: {
            ...mapActions('auth',[
                'setUser'
            ]),
        },
        created() {
            if(!this.user && store.get('user')) {
                this.setUser(store.get('user'))
            }
          document.addEventListener('gesturestart', function (e) {
            e.preventDefault();
          });
          document.ondblclick = function(e) {
            e.preventDefault();
          }
        }
    }
</script>
