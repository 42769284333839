/* AUTH */
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
/* DASHBOARD */
export const DASHBOARD_LOAD_LOCATION = 'DASHBOARD_LOAD_LOCATION'
export const DASHBOARD_TOGGLE_PAUSED = 'DASHBOARD_TOGGLE_PAUSED'
export const DASHBOARD_FILTER_ORDERS = 'DASHBOARD_FILTER_ORDERS'
export const DASHBOARD_UPDATE_OUTLETS = 'DASHBOARD_UPDATE_OUTLETS'
/* CATALOG */
export const CATALOG_SET_LOCATION = 'CATALOG_SET_LOCATION'
export const CATALOG_UPDATE_ITEM = 'CATALOG_UPDATE_ITEM'
export const CATALOG_GET_ITEMS = 'CATALOG_GET_ITEMS'
export const CATALOG_ERROR = 'CATALOG_ERROR'
export const CATALOG_ADD_TO_CART = 'CATALOG_ADD_TO_CART'
export const CATALOG_REMOVE_FROM_CART = 'CATALOG_REMOVE_FROM_CART'
export const CATALOG_RESET_CART = 'CATALOG_RESET_CART'
/* NOTIFICATION */
export const NOTIFICATION_UPDATE_SUCCESS = 'NOTIFICATION_UPDATE_SUCCESS'
export const NOTIFICATION_UPDATE_ERROR = 'NOTIFICATION_UPDATE_ERROR'

export const DASHBOARD_UPDATE_ORDER = 'DASHBOARD_UPDATE_ORDER'