<template>
  <div class="orders" :ref="'orders'" :class="{'orders--scrolled': scrolled}">
    <order v-for="order in openOrders" :key="order.id" :order="order" :history=false></order>
    <div class="orders__more-indicator"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapGetters} from 'vuex'
import Order from './Order'

export default {
  name: 'Orders',
  components: {
    Order,
  },
  data() {
    return {
      menuVisible: false,
      searchTerm: '',
      scrolled: false
    }
  },
  computed: {
    ...mapGetters('dashboard', [
      'location',
      'filterNumber',
      'outlets'
    ]),
    openOrders: function () {
      return this.location.orders.filter((order) => {
        return order.items.some((item) => {
          return this.outlets.indexOf(item.outlet_id) > -1 && !item.picked_up_at && !item.cancelled_at
        })
      })
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs['orders'].onscroll = () => {
        let el = this.$refs['orders'];
        this.scrolled = (el.scrollLeft > 50);
      }
    }, 300);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.orders {
  height: 85%;
  display: flex;
  justify-content: left;
  overflow: auto;
  padding: 0 0 15px;
  
  &--scrolled {
    margin-left: 30px;

    .orders__more-indicator {
      display: block;
    }
  }

  &__more-indicator {
    display: none;
    position: absolute;
    top: 45%;
    left: 25px;
    z-index: 11;

    width: 14px;
    height: 16px;

    transform: translateX(-50%) rotate(90deg);

    &::before {
      display: block;
      width: 14px;
      height: 16px;
      background: url('../assets/angle-down-solid.svg');
      background-size: cover;
      content: '';

      animation: bounceMoreOrdersIndicator 2s infinite;
    }
  }

  @keyframes bounceMoreOrdersIndicator {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(8px);
    }
    60% {
      transform: translateY(6px);
    }
  }
}
</style>
