import axios from 'axios'

export default {
    login(username, password) {
        // eslint-disable-next-line
        return axios.post(`${process.env.VUE_APP_API_URL}/login`,{
            "username" : username,
            "password" : password,
        })
    }
}
