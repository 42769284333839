<template>
  <div>
    <md-app md-mode="fixed">
      <md-app-toolbar class="toolbar md-primary" v-if="loaded" :class="{'md-accent toolbar--inactive' : paused}">
        <img v-if="location.picture" class="logo logo--bar" :src="location.picture" alt="location">
        <span v-if="!location.picture">{{ location.name }}</span>

        <md-button class="tab" :class="{'tab--active' : this.$router.currentRoute.name === 'dashboard'}"
                   @click="goToPage('dashboard')">
          <span>Bestellungen</span>
          <span class="dashboard__open-orders-badge"
                :class="{'dashboard__open-orders-badge--paused': paused}">{{ openOrders }}</span>
        </md-button>

        <md-button class="tab" :class="{'tab--active' : $router.currentRoute.name === 'history'}"
                   @click="goToPage('history')">
          <span>Verlauf</span>
        </md-button>

        <md-button class="tab" :class="{'tab--active' : $router.currentRoute.name === 'products'}"
                   @click="goToPage('products')">
          <span>Katalog</span>
        </md-button>

        <!--
        <div class="search" v-if="$router.currentRoute.name === 'dashboard'" @click="resetSearch()">
            <md-field>
                <span class="md-prefix">#</span>
                <md-input v-model="searchTerm"></md-input>
                <md-icon v-if="searchTerm.length > 0">close</md-icon>
            </md-field>
        </div>
        -->


        <div class="md-toolbar-section-end">
          <!--
          <div class="toolbar__tips" v-if="location.tips[0].sum_tips">
            <div class="toolbar__tips-inner md-body-1">{{ (location.tips[0].sum_tips / 100).toFixed(2) }}</div>
          </div>
          -->

          <md-switch v-model="pickUp" class="md-primary" @change="updatePickUp(pickUp)">Abholen</md-switch>

          <md-switch v-model="tableService" class="md-primary" @change="updateTableService(tableService)">Bringen
          </md-switch>

          <md-button class="md-icon-button" @click="toggleFullScreen">
            <md-icon>open_in_full</md-icon>
          </md-button>

          <md-button class="md-icon-label" @click="showOutletDialog = true">
            <md-icon>store</md-icon>
            ({{ outlets.length }}/{{ location.outlets.length }})
          </md-button>
          <md-button class="md-icon-button" @click="exit">
            <md-icon>exit_to_app</md-icon>
          </md-button>
        </div>

        <md-dialog :md-active.sync="showOutletDialog">
          <md-dialog-title>Outlets</md-dialog-title>

          <md-list>
            <md-list-item v-for="outlet in location.outlets" :key="'outlet_'+outlet.id">
              <md-checkbox v-model="outlets" :value="outlet.id"/>
              <span class="md-list-item-text">{{ outlet.name }}</span>
            </md-list-item>

          </md-list>

          <md-dialog-actions>
            <md-button class="md-primary" @click="showOutletDialog = false">Close</md-button>
            <md-button class="md-primary" @click="showOutletDialog = false">Save</md-button>
          </md-dialog-actions>
        </md-dialog>
      </md-app-toolbar>


      <slot></slot>
    </md-app>

    <snackbar></snackbar>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import store from 'store'
import Snackbar from './Snackbar'
import dashboard from '../api/dashboard';

import {
  doc,
  onSnapshot
} from "firebase/firestore";
import {db} from '@/firebaseInit';

export default {
  name: "Layout",
  components: {
    Snackbar
  },
  data() {
    return {
      searchTerm: '',
      tableService: null,
      pickUp: null,
      polling: null,
      fbUnsub: null,
      outlets: [],
      showOutletDialog: false,
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('dashboard', [
      'loaded',
      'location'
    ]),
    paused: function () {
      return (!this.tableService && !this.pickUp);
    },
    openOrders: function () {
      return this.location.orders.filter((order) => {
        return order.items.some((item) => {
          return this.outlets.indexOf(item.outlet_id) > -1 && !item.picked_up_at && !item.cancelled_at
        })
      }).length
    }
  },
  watch: {
    searchTerm: function (val) {
      this.updateFilterNumber(val)
    },
    outlets: function (newVal, oldVal) {
      if (oldVal !== null) {
        if (newVal.length === 0) {
          this.outlets.push(this.location.outlets.find(outlet => outlet.default).id)
        }
        store.set('outlets', this.outlets)
        this.updateOutlets([...this.outlets])
      }
    },
    location: function (val) {
      this.tableService = val.table_service;
      this.pickUp = val.pick_up;
    }
  },
  methods: {
    ...mapActions('auth', [
      'logout'
    ]),
    ...mapActions('dashboard', [
      'loadLocation',
      'refreshLocation',
      'updateFilterNumber',
      'updateOutlets'
    ]),
    updateTableService(status) {
       dashboard.updateTableService(this.location.id, status)
    },
    updatePickUp(status) {
       dashboard.updatePickUp(this.location.id, status)
    },
    exit: async function () {
      await this.logout(this.location.id);
      store.remove('user')
      this.$router.push('/login')
    },
    goToPage: function (name) {
      this.$router.push({name: name}).catch(() => {
      })
    },
    resetSearch() {
      if (this.searchTerm.length > 0) {
        this.searchTerm = ''
      }
    },
    toggleFullScreen() {
      if (document.fullscreenElement) {
        document
            .exitFullscreen()
      } else {
        document.documentElement.requestFullscreen();
      }
    }
  },
  async mounted() {
    this.$material.locale = this.locale
    this.loadLocation(this.user.location_id).then(() => {
      this.tableService = this.location.table_service;
      this.pickUp = this.location.pick_up;
      if (this.$refs.notification) {
        this.$refs.notification.load();
      }

      // init outlets
      if (store.get('outlets') && store.get('outlets').length > 0 && this.location.outlets.some(outlet => store.get('outlets').indexOf(outlet.id) > -1)) {
        this.outlets = store.get('outlets').filter(outlet => this.location.outlets.some(locationOutlet => locationOutlet.id === outlet))
      } else {
        this.outlets = this.location.outlets.map(outlet => {
          return outlet.default ? outlet.id : null
        }).filter(outlet => outlet !== null)
      }

      this.fbUnsub = onSnapshot(doc(db, `merchant_locations/${this.location.slug}`), async () => {
        await this.refreshLocation(this.user.location_id)
      })
    }, function (error) {
      if (error.response && error.response.status == 401) {
        store.remove('user')
        this.$router.push('/login')
      }
    }.bind(this))

    this.polling = setInterval(function () {
      this.refreshLocation(this.user.location_id)
    }.bind(this), 30000)
  },
  beforeDestroy() {
    clearInterval(this.polling)
    if (this.fbUnsub) {
      this.fbUnsub()
    }
  }
}
</script>
