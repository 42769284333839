import * as types from '../mutation-types'

// initial state
const state = {
    error: null,
    success: null,
}

// getters
const getters = {
    error: state => state.error,
    success: state => state.success
}

// actions
const actions = {
    updateSuccess({commit}, success) {
        commit(types.NOTIFICATION_UPDATE_SUCCESS, success)
    },
    updateError({commit}, error) {
        commit(types.NOTIFICATION_UPDATE_ERROR, error)
    }
}
// mutations
const mutations = {
    [types.NOTIFICATION_UPDATE_SUCCESS](state, data) {
        state.success = data
    },
    [types.NOTIFICATION_UPDATE_ERROR](state, data) {
        state.error = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
