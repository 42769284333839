<template>
  <md-snackbar md-position="center" :md-duration="2500" :md-active.sync="showSnackbar">
    <span>{{ message }}</span>
  </md-snackbar>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: "Snackbar",
        data() {
            return {
                showSnackbar: false
            }
        },
        computed: {
            ...mapGetters('notification', [
                'error',
                'success'
            ]),
            message: function() {
                return this.success ? this.success : this.error
            }
        },
        watch: {
            success: function() {
                this.showSnackbar = true

                setTimeout(function() {
                    this.showSnackbar = false
                    this.reset()
                }.bind(this),2500)
            },
            error: function() {
                this.showSnackbar = true

                setTimeout(function() {
                    this.showSnackbar = false
                    this.reset()
                }.bind(this),2500)
            }
        },
        methods: {
            ...mapActions('notification', [
                'updateSuccess',
                'updateError'
            ]),
            reset: function() {
                this.updateSuccess(null)
                this.updateError(null)
            }
        }
    }
</script>