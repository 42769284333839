import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import localstore from 'store'
import IdleVue from 'idle-vue'
import axios from 'axios';
import VueMaterial from 'vue-material'
import './styles/app.scss'
import 'vue-material/dist/vue-material.min.css'

if(localstore.get('user')) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localstore.get('user').token}`;
}


Vue.use(IdleVue, { store: store, idleTime: 120000, startAtIdle: false })

Vue.use(VueMaterial)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
