import axios from 'axios'

var refreshLocationCall
export default {
    loadLocation(locationId) {
        // eslint-disable-next-line
        return axios.get(`${process.env.VUE_APP_API_URL}/locations/`+locationId);
    },
    refreshLocation(locationId) {
        // eslint-disable-next-line
        if (refreshLocationCall) {
            refreshLocationCall.cancel();
        }
        refreshLocationCall = axios.CancelToken.source();
        return axios.get(`${process.env.VUE_APP_API_URL}/locations/`+locationId, {
            cancelToken: refreshLocationCall.token
        });
    },
    updateTableService(locationId, status) {
        return axios.put(`${process.env.VUE_APP_API_URL}/locations/`+locationId, {
            table_service: status
        });
    },
    updatePickUp(locationId, status) {
        return axios.put(`${process.env.VUE_APP_API_URL}/locations/`+locationId, {
            pick_up: status
        });
    },
    ready(orderId, items) {
        // eslint-disable-next-line
        return axios.put(`${process.env.VUE_APP_API_URL}/orders/`+orderId, {
            'action': 'ready',
            'items': items
        });
    },
    pickUp(orderId, items) {
        // eslint-disable-next-line
        return axios.put(`${process.env.VUE_APP_API_URL}/orders/`+orderId, {
            'action': 'pickup',
            'items': items
        });
    },
    remind(orderId) {
        // eslint-disable-next-line
        return axios.put(`${process.env.VUE_APP_API_URL}/orders/`+orderId, {'action': 'remind'});
    },
    cancel(orderId, items) {
        // eslint-disable-next-line
        return axios.put(`${process.env.VUE_APP_API_URL}/orders/`+orderId, {
            'action': 'cancel',
            'items': items
        });
    },
    logout(locationId) {
        // eslint-disable-next-line
        return axios.post(`${process.env.VUE_APP_API_URL}/logout/`, {
            location_id: locationId
        });
    }
}
